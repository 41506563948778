<template>
  <div
    v-loading="!loaded"
    class="partner-group"
  >
    <GroupHeader />

    <ElForm
      :model="group"
      :rules="validationRules"
      ref="form"
    >
      <GroupSettings
        :model="group"
        :strategy-payment-types="strategyPaymentTypes"
      />
      <GroupTargeting
        v-if="loaded"
        :model="group"
      />
      <GroupAlerts
        v-if="['cpa', 'ppva'].includes(group.strategy_payment)"
        :model="group"
      />
    </ElForm>

    <GroupActions />
  </div>
</template>

<script>
import store from 'views/Partner/pages/PartnerGroup/BrandAwarenessGroup/store'

import ElForm from 'element/ElForm'
import loading from 'element/Loading'

import GroupActions from './components/GroupActions'
import GroupAlerts from './components/GroupAlerts.vue'
import GroupHeader from './components/GroupHeader'
import GroupSettings from './components/GroupSettings'
import GroupTargeting from './components/GroupTargeting'
import validationRules from './validationRules'

export default {
  components: {
    GroupHeader,
    ElForm,
    GroupSettings,
    GroupTargeting,
    GroupActions,
    GroupAlerts,
  },

  directives: { loading },

  computed: {
    group: ({ $store }) => $store.getters['group/group'],
    loading: ({ $store }) => $store.getters['group/loading'],
    isEdit: ({ $route }) => $route.name === 'edit-group',
    loaded: ({ loading, group }) => !loading && group.bootstrapped,
    validationRules: () => validationRules(),
    user: ({ $store }) => $store.getters['auth/user'],
    agencies: ({ $store }) => $store.getters['dictionaries/agencies'],
    strategyPaymentTypes: ({ $store }) => $store.getters['dictionaries/strategyPaymentTypes'],
  },

  beforeCreate () {
    if (this.$store.hasModule('group')) return
    this.$store.registerModule('group', store)
  },
  beforeDestroy () {
    this.$store.dispatch('group/reset')
  },
  async created () {
    await this.$store.dispatch('dictionaries/fetchAwarenessCampaignDictionaries')
    await this.getGroup()

    if (this.isEdit) {
      document.title = `${this.group.title} - Uplify`
    }
  },

  methods: {
    async getGroup (routeTo) {
      const routeName = routeTo?.name || this.$route.name
      const groupSlug = routeTo?.params.groupSlug || this.$route.params.groupSlug

      if (routeName === 'edit-group') {
        await this.$store.dispatch('group/fetch', groupSlug)
      }
      else if (routeName === 'create-group') {
        this.createNewGroup(routeTo)
      }
    },

    createNewGroup (routeTo) {
      const agencyId = this.agencies.find(agency => agency?.id == this.user?.agency?.id)?.id || 1
      const campaignSlug = routeTo?.params.campaignSlug || this.$route.params.campaignSlug
      const data = { campaign: { slug: campaignSlug }, targeting: { agencies: [{ id: agencyId }] } }

      this.$store.commit('group/toggleLoading', true) // need for usability
      setTimeout(() => {
        this.$store.commit('group/setGroup', data)
        this.$store.commit('group/toggleLoading', false)
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
.partner-group {
  width: 100%;
  min-height: calc(100vh - #{$--layout-padding-vertical});
  padding-bottom: 115px;
  position: relative;

  &__title {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::v-deep {
    .el-loading-spinner {
      top: calc(50vh - 20px);
    }
  }
}
</style>
